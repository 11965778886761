const load_animations = async () => {
  await import('~/javascripts/slide_reveal');
  await import('~/javascripts/animations/home');
  await import('~/javascripts/home_ambassadors');
  await import('~/javascripts/home_social');
};

const trigger = document.getElementById('header-scroll-observer');
const triggerObserver = new IntersectionObserver(
  (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        return;
      }

      load_animations();

      triggerObserver.unobserve(trigger);
    });
  },
  {
    rootMargin: '80px',
  }
);

triggerObserver.observe(trigger);

const paradiseDates = document.getElementsByClassName('paradise-1m');
const paradiseEvent = document.getElementById('paradise-1m-event');

const christmasDates = document.getElementsByClassName('christmas-12m');
const christmasEvent = document.getElementById('christmas-12m-event');

const flipoutDates = document.getElementsByClassName('flipout-1m');
const flipoutEvent = document.getElementById('flipout-1m-event');

function eventTrigger(trigger, event) {
  for (var i = 0; i < trigger.length; i++) {
    trigger[i].addEventListener('mouseover', () => {
      event.classList.remove('opacity-0');
      event.classList.add('opacity-100');
    });
    trigger[i].addEventListener('mouseout', () => {
      event.classList.remove('opacity-100');
      event.classList.add('opacity-0');
    });
  }
}

eventTrigger(paradiseDates, paradiseEvent);
eventTrigger(christmasDates, christmasEvent);
eventTrigger(flipoutDates, flipoutEvent);
